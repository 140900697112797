import * as React from "react"
import Layout from "../components/layout"
import '../components/index.css'
import { TextField } from "@mui/material";
import { useState } from "react";
import '../components/signup.css'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const pageStyles = {
    color: "#232129",
    fontFamily: "Roboto, sans-serif, serif",
}

const AboutYou = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    return <>
        <h2 style={{ textAlign: "center" }}>Email and password</h2>
        <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginTop: "2rem" }} />
        <TextField type="password" fullWidth label="Password" onChange={(e) => setPassword(e.target.value)} sx={{ marginTop: "2rem" }} />
        <div
            onClick={() => alert("TODO")}
            style={{
                padding: "1rem",
                background: "#007788",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                marginTop: "2rem",
                cursor: "pointer"
            }}><b>Sign up</b></div>
        {error && <div style={{ color: "red" }}>{error}</div>}
    </>
}

const AboutYourProject = ({ nextCallback }) => {
    const [projectDescription, setProjectDescription] = useState("")
    const [solutionsSelected, setSolutionsSelected] = useState([])
    const [planSelected, setPlanSelected] = useState("developer")
    const [error, setError] = useState("")

    return <>
        <h2 style={{ textAlign: "center" }}>Tell us about your project</h2>
        <div style={{ marginTop: "2rem" }}>Please describe in a few words how you will be using our APIs:</div>
        <TextField
            multiline
            minRows={3}
            maxRows={5}
            size="small"
            fullWidth
            placeholder="Enter description"
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            sx={{ marginTop: "0.5rem" }} />
        <div style={{ marginTop: "2rem", marginBottom: "0.5rem" }}>You want access to: </div>
        <div className="solution-selector">
            <div
                onClick={() => {
                    const i = solutionsSelected.findIndex(v => v === "food-info")
                    if (i > -1) {
                        setSolutionsSelected(solutionsSelected.filter(v => v !== "food-info"))
                    } else {
                        setSolutionsSelected(["food-info", ...solutionsSelected])
                    }
                }}
                className={solutionsSelected.includes("food-info") ? "selected" : ""}>
                <div>The Food Information API</div>
                <div style={{ marginTop: "0.5rem" }}>{solutionsSelected.includes("food-info") ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />}</div>
            </div>
            <div
                onClick={() => {
                    const i = solutionsSelected.findIndex(v => v === "autocomplete")
                    if (i > -1) {
                        setSolutionsSelected(solutionsSelected.filter(v => v !== "autocomplete"))
                    } else {
                        setSolutionsSelected(["autocomplete", ...solutionsSelected])
                    }
                }}
                className={solutionsSelected.includes("autocomplete") ? "selected" : ""}>
                <div>The Autocomplete API</div>
                <div style={{ marginTop: "0.5rem" }}>{solutionsSelected.includes("autocomplete") ? <TaskAltIcon /> : <RadioButtonUncheckedIcon />}</div>
            </div>
        </div>
        <div style={{ marginTop: "2rem", marginBottom: "0.5rem" }}>Choose a plan: </div>
        <FormControl fullWidth>
            <Select
                id="demo-simple-select"
                value={planSelected}
                onChange={(e) => setPlanSelected(e.target.value)}
            >
                <MenuItem value={"developer"}>Developer</MenuItem>
                <MenuItem value={"production"}>Production</MenuItem>
                <MenuItem value={"snapshot"}>Snapshot</MenuItem>
            </Select>
        </FormControl>
        <div
            onClick={() => {
                if (!projectDescription) setError("Project description required.")
                else if (solutionsSelected.length === 0) setError("Select at least one API.")
                else nextCallback(projectDescription, solutionsSelected, planSelected)
            }}
            style={{
                padding: "1rem",
                background: "#007788",
                textAlign: "center",
                borderRadius: "4px",
                color: "white",
                marginTop: "2rem",
                cursor: "pointer"
            }}><b>Next</b></div>
        {error && <div style={{ color: "red" }}>{error}</div>}
    </>
}

const IndexPage = ({ location }) => {
    const [step, setStep] = useState(1)


    React.useEffect(() => {
        fetch('https://api.thefoodapi.com/account/info', {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: "include"
        }).then(resp => {
            if (resp.status === 200) {
                window.location = '/account'
            }
        })
    }, [])

    return (
        <Layout location={location}>
            <div style={pageStyles}>
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{
                        width: "30rem",
                        maxWidth: "95%",
                        background: "white",
                        borderRadius: "8px",
                        padding: "2rem 1rem",
                        marginTop: "5rem"
                    }}>
                        <div>
                            <div style={{ textAlign: "center" }}>Step {step} / 2</div>
                        </div>
                        {step === 1 && <AboutYourProject nextCallback={() => setStep(2)} />}
                        {step === 2 && <AboutYou />}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const Head = () => <title>Register Page</title>
